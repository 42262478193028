import React from "react";
import { Card, Grid } from '@mui/material';

export type Images = {
    FaceID: string;
    ImageURL: string;
    PictureID: string;
}

type ImageListProps = {
    images?: Images[];
    onDelete: (faceId: string, pictureId: string) => void;
}

const ImageList: React.FC<ImageListProps> = ({images, onDelete}) => {
    if(images === undefined || images.length === 0){
        return (<></>)
    }else{

        return (
            <Grid container spacing={2}>
              {images.map((item) => (
                  <Grid item key={item.FaceID} xs={4} sm={4} md={4}>
                    <Card onClick={() => onDelete(item.FaceID, item.PictureID)}>
                      <img alt="face" src={`data:image/jpeg;base64,${item.ImageURL}`} style={{width:'100%', height: '100%', objectFit:'cover'}}></img>
                    </Card>
                  </Grid>
              ))}
            </Grid>
          )
    }
}

export default ImageList;