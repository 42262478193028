import React from 'react';
import { Alert, Snackbar } from '@mui/material';

type NotificationAlertProps = {
    //ダイアログを開くか
    open: boolean,
    // ダイアログタイプ(成功、情報、警告、エラー)
    type:  'success' | 'info' | 'warning' | 'error',
    // 表示するコンテンツ
    content:string,
    // ダイアログを閉じる処理
    onClose:() => void
}

const NotificationAlert:React.FC<NotificationAlertProps> = ({open, type,  content, onClose}) => {
    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
            <Alert severity={type} sx={{width: '100%'}}>
                {content}
            </Alert>
        </Snackbar>
    )
}

export default NotificationAlert;