import "./App.css";
import Box from "@mui/material/Box";

import FaceRecognition from "./components/Face/faceRecognition";
import FaceRegister from "./components/Face/faceRegister";
import { AccessTokenProvider } from "./components/accessTokenProvider";
import { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";

function App() {
    const mode = getParam("mode");
    const scoreThreshold = getParam("st");
    const inputSize = getParam("size");
    const spanMS = getParam("ms");
    const deviceId = getParam("deviceID");
    const exposureCompensation = getParam("ec");

    if (process.env.REACT_APP_ENV === "production") {
        console.log(`Production environment`);
    } else {
        console.log(`staging environment`);
        console.log(process.env.NODE_ENV);
        console.log(process.env.REACT_APP_ENV);
    }

    const [accessToken, setAccessToken] = useState<string>("");

    useEffect(() => {
        const initialize = async () => {
            const baseURL = process.env.REACT_APP_WEBAPI_BASEURL!;
            console.log(`[APP] Base Request URL: ${baseURL}`);
            const provider = new AccessTokenProvider(baseURL);
            provider.getAccessToken().then((token) => {
                setAccessToken(token);
            });
        };

        initialize();
    }, []);

    if (!accessToken) {
        return <div>Loading...</div>;
    }

    return (
        <SnackbarProvider
            maxSnack={2}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Box sx={{ flexGrow: 1 }}>
                {/* 顔写真追加画面 */}
                {mode === "regist" && (
                    <FaceRegister accessToken={accessToken} />
                )}

                {/* 顔認証画面 */}
                {/* mode指定がない場合は顔認証画面を表示 */}
                {mode === "" && (
                    <FaceRecognition
                        accessToken={accessToken}
                        scoreThreshold={Number(scoreThreshold)}
                        inputSize={Number(inputSize)}
                        spanMS={Number(spanMS)}
                        deviceId={deviceId}
                        exposureValue={Number(exposureCompensation)}
                    />
                )}
            </Box>
        </SnackbarProvider>
    );
}

/**
 * URLのクエリ文字列から指定した文字列の値を取り出す
 * @param {string} name 指定する文字列
 * @param {string} url ページのURL
 */
function getParam(name: string, url?: string): string {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);

    if (!results) {
        return "";
    }

    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default App;
