import axios from "axios";

type getUserInfoResponse = {
    userId: number;
    imageCount: number;
    personId: string | null;
    status: number;
    message?: string;
}

export type GetImageListResponse = {
    ImageURL: string;
    FaceID  : string;
    PictureID: string;
}


/**
 * ユーザ情報コントローラクラス
 */
class UsersController {
    constructor(){
        console.log(`[MemberController] start constructor`);
    }

    /**
     * LINEUserIDからメンバーIDと写真追加数を取得する
     * @param lineUserId LINEのユーザID
     */
    async getUserInfo(lineUserId: string, accessToken: string){
        console.log(`[START] getUserInfo lineUserId=${lineUserId} token:${!!accessToken}`);
        const url = process.env.REACT_APP_WEBAPI_BASEURL + '/Users/GetUserInfo/' + lineUserId;

        let result:getUserInfoResponse;

        const headers = {
            "Authorization": "Bearer " + accessToken
        }

        return axios.get(url, { headers: headers }).then(res => {
            result = res.data;
            if(result.status !== 200){
                console.error(result.message);
            }

            console.log(`getUserInfo result: ${JSON.stringify(result)}`);
            return result;

        }).catch(error => {
            console.error(error);
            return error;
        })
    }

    /**
     * 登録済みの顔写真一覧を取得します
     * @param userId ユーザID
     * @returns 顔写真の一覧
     */
    async getImageList(userId: number, accessToken: string){
        console.log(`start getImageList userID:${userId}`);

        const url = process.env.REACT_APP_WEBAPI_BASEURL + '/Users/GetImages/' + userId;

        let result:GetImageListResponse[] = [];

        const headers = {
            "Authorization": "Bearer " + accessToken
        }

        return axios.get(url, { headers: headers }).then(res => {
            result = res.data.images;

            console.log(`getImageList get success`);
            return result;
        })
    }


}

export const Users = new UsersController();