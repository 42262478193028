import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import * as faceapi from "face-api.js";
// import * as faceapi from '@vladmandic/face-api/dist/face-api.node-wasm';

import { Box, Button, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import Iframe from "react-iframe";
import {
    FullScreen,
    FullScreenHandle,
    useFullScreenHandle,
} from "react-full-screen";
import { VariantType, useSnackbar } from "notistack";

import fig from "../../logos/fig.png";
import useSound from "use-sound";
import Sound from "../../soundeffect/success-sound.mp3";
import useWindowSize from "../Hooks/useWindowSize";

type FaceRecognitionProps = {
    accessToken: string;
    scoreThreshold?: number;
    inputSize?: number;
    spanMS?: number;
    deviceId: string;
    exposureValue: number;
};

type ShowResultType = {
    engine: string;
    isSuccess: boolean;
    name?: string;
    score?: string;
    error?: string;
};

let isLoading = false;
let timeoutID: any = null;

/**
 * 顔認証コンポーネント
 */
const FaceRecognition: React.FC<FaceRecognitionProps> = ({
    accessToken,
    scoreThreshold = 0.5,
    inputSize = 512,
    spanMS = 500,
    deviceId,
    exposureValue,
}) => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [memberName, setMemberName] = useState<string>("");
    const [canvasWidth, setCanvasWidth] = useState<number>(0);
    const [canvasHeight, setCanvasHeight] = useState<number>(0);

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const pictureRef = useRef<HTMLCanvasElement>(null);
    const soundRef = useRef<HTMLButtonElement>(null);

    const { enqueueSnackbar } = useSnackbar();

    /**
     * 体温情報関連
     */
    const [useWidth, useHeight] = useWindowSize();

    // const isDisplayAvatar: boolean = useMediaQuery({ minHeight:900 });
    const isDisplayAvatar: boolean =
        deviceId === "device-1001" || deviceId === "device-9999";

    const fullscreenHandle: FullScreenHandle = useFullScreenHandle();
    const [play] = useSound(Sound, {
        volume: 1,
        onload: () => console.log(`sound loaded.`),
    }); // 成功時の効果音

    const videoWidth: number = 1080;
    const videoHeight: number = 1080;

    const constraints = {
        audio: false,
        video: {
            width: {
                min: videoWidth,
                ideal: videoWidth,
            },
            height: {
                min: videoHeight,
                ideal: videoHeight,
            },
            frameRate: 30,
        },
    };

    // 初期化処理の実行
    useEffect(() => {
        // initialize();
        let track: any;
        const getCameraStream = async () => {
            // カメラに接続
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream: MediaStream) => {
                    videoRef.current!.srcObject = stream.clone();
                    track = stream.getVideoTracks()[0];

                    // 露光値が設定されている場合のみ適用する
                    if (exposureValue) {
                        // 取得したストリームに制約を送信
                        // 露光をマニュアル、露光値を設定
                        setTimeout(() => {
                            track.applyConstraints({
                                advanced: [{ exposureMode: "manual" }],
                            });
                            track.applyConstraints({
                                advanced: [
                                    { exposureCompensation: exposureValue },
                                ],
                            });
                        }, 5000);
                    }
                })
                .catch((error) => {
                    console.log(`カメラデバイスの取得に失敗しました:${error}`);
                    setTimeout(() => {
                        getCameraStream();
                    }, 3000);
                });
        };

        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + "/facemodels";

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                // faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ]).then(() => {
                console.log(`[FaceModel] Loaded`);
            });
        };

        // 顔認識用のモデル読み込み
        loadModels();

        // カメラの取得
        getCameraStream();

        // await video stream playing
        videoRef.current?.addEventListener("playing", () => {
            setCanvasWidth(videoRef.current?.offsetWidth!);
            setCanvasHeight(videoRef.current?.offsetHeight!);

            // 露光値が設定されている場合のみ適用する
            if (exposureValue) {
                // 取得したストリームに制約を送信
                // 露光をマニュアル、露光値を設定
                track.applyConstraints({
                    advanced: [{ exposureMode: "manual" }],
                });
                track.applyConstraints({
                    advanced: [{ exposureCompensation: exposureValue }],
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 顔認識を行います(1秒間隔)
     */
    const handleVideoOnPlay = () => {
        // console.log(`inputSize: ${inputSize}, scoreThreshold:${scoreThreshold}, span=:${spanMS}`);

        const options: faceapi.TinyFaceDetectorOptions =
            new faceapi.TinyFaceDetectorOptions({
                inputSize,
                scoreThreshold,
            });

        setInterval(async () => {
            if (!videoRef.current) {
                return;
            }

            // 顔認識処理
            const detections: faceapi.FaceDetection | undefined =
                await faceapi.detectSingleFace(videoRef.current!, options);

            clearRectangle();

            if (detections) {
                // 矩形情報から大きすぎるものを排除
                if (detections.box.width * detections.box.height > 170000) {
                    // 誤検知として扱い、リクエストは送らないようにする
                    return;
                } else {
                    drawRectangle();

                    onRecognition(detections);
                }
            }
        }, spanMS);
    };

    /**
     * 顔認証を行います
     * @param detections 顔認識時の顔座標など
     */
    const onRecognition = (detections: faceapi.FaceDetection) => {
        if (isLoading || accessToken === "") {
            // console.log("リクエスト中のため中断");
            return;
        }

        isLoading = true;

        const canvas = pictureRef.current!;
        const context = canvas.getContext("2d")!;
        const video = videoRef.current!;

        if (canvas.offsetWidth < 0 || canvas.offsetHeight < 0) {
            console.log(
                `width:${canvas.offsetWidth}, height:${canvas.offsetHeight}`
            );
            return;
        }
        // 顔写真加工なし
        context.drawImage(video, 0, 0, canvas.offsetWidth, canvas.offsetHeight);

        const base64Data = pictureRef.current!.toDataURL("image/jpeg", 0.85);
        const data = {
            imageData: base64Data,
            deviceID: deviceId,
            detections: JSON.stringify(detections),
            // bodyTemperature: bodytemp,
            watchlistID: 34, // REALIZEビル専用(一旦固定にしとく)
        };

        const headers = {
            Authorization: "Bearer " + accessToken,
        };

        const baseUrl = process.env.REACT_APP_WEBAPI_BASEURL;

        const necURL = baseUrl + "/NeoFace/matchImage";
        const awsURL = baseUrl + "/Rekognition/SearchFacesByImage";

        const start = performance.now();
        Promise.all([
            axios.post(necURL, data, { headers: headers }),
            axios.post(awsURL, data, { headers: headers }),
        ])
            .then(([{ data: necData }, { data: awsData }]) => {
                console.log(`顔認証終了 Time: ${performance.now() - start}`);

                const memberName =
                    awsData.memberName || necData.response.memberName;
                setMemberName(memberName);
                const isSuccess =
                    awsData.isSuccess || necData.response.isSuccess;
                if (isSuccess) {
                    setIsSuccess(isSuccess);
                    if (process.env.NODE_ENV === "production") {
                        openTheDoorPublish();
                        if (!isDisplayAvatar) {
                            soundPlay();
                        }
                    }
                }

                const awsShowResult: ShowResultType = {
                    engine: "AWS",
                    isSuccess: awsData.isSuccess,
                    name: awsData.memberName,
                    score: awsData.score,
                    error: awsData.error,
                };

                const necShowResult: ShowResultType = {
                    engine: "NEC",
                    isSuccess: necData.response.isSuccess,
                    name: necData.response.memberName,
                    score: necData.response.score,
                    error: necData.response.error,
                };

                showResult(awsShowResult);
                showResult(necShowResult);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTimeout(() => {
                    isLoading = false;
                }, 3000);

                // 表示フラグの管理
                // 続けてリクエストが来た場合に表示し続ける
                if (timeoutID) {
                    clearTimeout(timeoutID);
                    timeoutID = null;
                }

                const timer = setTimeout(() => {
                    setIsSuccess(false);
                    isLoading = false;
                    clearTimeout(timer);
                }, 3000);
                timeoutID = timer;
            });
    };

    /**
     * 顔認識時の矩形表示
     */
    const drawRectangle = () => {
        const canvas = canvasRef.current;
        if (!canvas) throw new Error("canvas is undefined");
        const context = canvas.getContext("2d");
        if (!context) throw new Error("context not found");

        // 背景の塗りつぶし(canvas範囲)
        context.fillStyle = "#23B8BC75";
        context.fillRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);

        // 部分的に切り取るための設定
        context.globalCompositeOperation = "destination-out";

        // 円を切り抜く
        context.beginPath();
        context.fillStyle = "#000F";
        context.arc(
            canvas.offsetWidth / 2,
            canvas.offsetHeight / 2,
            canvas.offsetWidth * 0.72 > 500 ? 500 : canvas.offsetWidth / 2,
            0,
            Math.PI * 2
        );
        context.fill();

        context.globalCompositeOperation = "source-over"; // 描画設定戻す
    };

    /**
     * 矩形クリア
     */
    const clearRectangle = () => {
        const canvas = canvasRef.current!;
        const context = canvas.getContext("2d")!;

        context.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
    };

    /**
     * ドア開錠用のトピックをPublishします
     */
    const openTheDoorPublish = async () => {
        const headers = {
            Authorization: "Bearer " + accessToken,
        };

        const url = process.env.REACT_APP_WEBAPI_BASEURL + `/OpenTheDoor`;

        axios
            .post(url, { deviceId: deviceId }, { headers: headers })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * 顔照合結果を表示します(確認用)
     * @param data 顔照合の結果(AWS, NEC)
     */
    const showResult = (data: ShowResultType) => {
        console.log("認証結果を表示します:", data.engine);
        const variant: VariantType =
            data.isSuccess === true ? "success" : "error";
        console.dir(data);

        const message: React.ReactNode = (
            <div className="result-font-size">
                ({data.engine}):({data.isSuccess}):({data.name})({data.score})
                {data.error ? ":" + data.error : ""}
            </div>
        );

        enqueueSnackbar(message, { variant });
    };

    /**
     * 成功サウンドを鳴らします
     */
    const soundPlay = () => {
        if (soundRef.current) {
            soundRef.current.click();
        }
    };

    const testShow = () => {
        console.log("test show alert");
        const result: ShowResultType = {
            engine: "test",
            name: "Yamamoto Takumi",
            isSuccess: true,
            score: "0.982374928374",
        };
        showResult(result);
    };

    return (
        <>
            <FullScreen handle={fullscreenHandle}>
                <div style={{ backgroundColor: "#fff" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <video
                            id="face-camera"
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                            width={useWidth}
                            height={useWidth > useHeight ? useHeight : useWidth}
                            onPlay={handleVideoOnPlay}
                            style={{
                                borderRadius: "10px",
                            }}
                        ></video>
                        {/* 顔認識の矩形表示 */}
                        <canvas
                            id="face-canvas"
                            ref={canvasRef}
                            width={canvasWidth}
                            height={canvasHeight}
                            style={{ position: "absolute", top: 0, left: 0 }}
                        ></canvas>
                        <canvas
                            id="picture"
                            style={{
                                visibility: "hidden",
                                position: "absolute",
                            }}
                            ref={pictureRef}
                            width={canvasWidth}
                            height={canvasHeight}
                        ></canvas>
                        <button
                            ref={soundRef}
                            style={{ display: "none" }}
                            onClick={() => play()}
                        >
                            Sound
                        </button>
                    </Box>

                    <div className="success-alert-message">
                        {isSuccess === true && (
                            <>
                                <div className="name-display-message">
                                    {memberName} さん
                                </div>
                            </>
                        )}
                    </div>
                    <Button onClick={() => testShow()}>TEST</Button>
                    {/* デバイスIDによって表示・非表示を切り替える */}
                    {isDisplayAvatar && (
                        <>
                            <div className="avater-space">
                                <Iframe
                                    id="avater-iframe"
                                    url={process.env.REACT_APP_AVATER_SITE_URL!}
                                    width={"800px"}
                                    height={"800px"}
                                    display="block"
                                    scrolling="no"
                                    frameBorder={0}
                                />
                            </div>
                        </>
                    )}
                    {fullscreenHandle.active && (
                        <div className="fullscreen-button">
                            <IconButton onClick={fullscreenHandle.exit}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </FullScreen>

            {isDisplayAvatar && (
                <div className="display-logos">
                    <img alt="fig" src={fig} />
                </div>
            )}

            {!fullscreenHandle.active && (
                <div className="fullscreen-button">
                    <IconButton
                        className="fullscreen-button"
                        onClick={fullscreenHandle.enter}
                    >
                        <FullscreenIcon />
                    </IconButton>
                </div>
            )}
        </>
    );
};

export default FaceRecognition;
