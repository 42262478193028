import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { liff } from '../../liff';

// MUI Components
import {
    Stack,
    AppBar,
    Button,
    Typography,
    Container,
    IconButton,
} from '@mui/material';

// MUI Icons
import {
    PhotoCamera
} from '@mui/icons-material';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { Users } from '../../controllers/users';
import NotificationAlert from '../Alert/NotificationAlert';
import RegistConfirmDialog from '../Dialog/RegistConfirmDialog';
import ResultDialog from '../Dialog/ResultDialog';
import ImageList, { Images } from '../List/ImageList';
import InformationDialog from '../Dialog/InformationDialog';

type FaceRegisterProps = {
    accessToken: string;
}

type NotifyType = 'success' | 'info' | 'warning' | 'error';

type DetectFacesResponse = {
    isRegist: boolean,
    reasons: string[]
}

const MAX_IMAGE_COUNT = 3;
let imageCount = 0;

/**
 * 顔追加用コンポーネント
 * @returns
 */
const FaceRegister: React.FC<FaceRegisterProps> = (props) => {
    // Ref関係
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const videoSize = 400;

    // カメラの制約
    const constrains = {
        audio:false,
        video: {
            aspectRatio:1,
            facingMode:'user',
            width:videoSize,
            height:videoSize
        }
    }

    // state関係
    const [userId, setUserId] = useState<number>(0);
    const [personId, setPersonId] = useState<string>('');
	const [stream, setStream] = useState<MediaStream | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = useState<string>("");
    const [notifyType, setNotifyType] = useState<NotifyType>('info'); 

    const [open, setOpen] = useState<boolean>(false);
    const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
    const [openRegistDialog, setOpenRegistDialog] = useState<boolean>(false);
    const [openResult, setOpenResult] = useState<boolean>(false);
    const [registResult, setRegistResult] = useState<DetectFacesResponse>({isRegist:false, reasons:[]});

    const [registImageUrl, setRegistImageUrl] = useState<string>('');
    const [cameraLoaded, setCameraLoaded] = useState<boolean>(false);

    const [imageListData, setImageListData] = useState<Images[]>([]);

    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [isWindowClose, setIsWindowClose] = useState<boolean>(false);


    const initialize = async (lineId: string) => {
        console.log(`lineID:${lineId}`);
        if(lineId){
            getCamera();

            // LINEUserIDでユーザＩD取得
            const response = await Users.getUserInfo(lineId, props.accessToken);
            if(response && response.userId){
                setUserId(response.userId);
                setNotify('success', `ユーザを確認しました`);
                getRegisteredImages(response.userId);
                if(response.imageCount > 0 && response.personId == null){
                    // 再登録を促すようにアラート表示
                    const title = 'お知らせ';
                    const content = `NEC向け顔認証への登録に失敗しました。\n顔写真の再登録にご協力をお願いいたします。\n※1F/3F顔認証の利用は今まで通り行えますがご協力をお願いいたします。`;
                    setInformationDialog(title, content, false);

                }else if(response.personId != null){
                    setPersonId(response.personId);
                }
            }else{
                // メアド未登録
                const title = 'メールアドレスが未登録です';
                const content = 'リッチメニューの「メール登録」より社内メールアドレスを入力してください';
                setInformationDialog(title, content, true);
            }

        }else{
            setNotify('error', "LINEIDの取得に失敗しました");
        }

    }

    // カメラの取得
    const getCamera = () => {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            navigator.mediaDevices.getUserMedia(constrains)
            .then(stream => {
                videoRef.current!.srcObject = stream.clone();
                setStream(stream);

                console.log(`useEffect loaded video device`);
                setCameraLoaded(true);
            }).catch(err => {
                setNotify('error', "カメラの取得に失敗しました。" + err.message);
            })
        }else{
            setNotify('error', "ご利用中の端末ではカメラが対応しておりません。");
        }
    }

    /**
     * 登録済みの顔写真を取得します
     * @param userId ユーザID
     */
    const getRegisteredImages = async (userId: number): Promise<void> => {
        const imageList: Images[] = await Users.getImageList(userId, props.accessToken);
        imageCount = imageList.length;
        setImageListData(imageList);
    }


    // 初期化用
    useEffect(() => {
        const liffId = process.env.REACT_APP_LIFF_ID;

        // test
        if(process.env.NODE_ENV === 'development'){
            initialize('U0691344318a54a99bacd9c0e0217bf64');
        }

        liff.init({liffId: liffId}).then(async () => {
            // LINEUserID取得
            const lineUserId = await liff.getDecodedIDToken().sub;

            initialize(lineUserId);
        }).catch((err:any) => {
            console.error(`failed liff init error code: ${err.code} message: ${err.message}`);
            setNotify('error', "LIFFの初期化に失敗しました。" + err.message);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	// Stream Initialize and Update
	useEffect(() => {
		if(videoRef.current && stream){
			videoRef.current.srcObject = stream;
			videoRef.current.play();
		}
	}, [stream])

	const switchCamera = async () => {
		if(stream){
			const videoTrack = stream.getVideoTracks()[0];
			const facingMode = videoTrack.getSettings().facingMode;
            constrains.video.facingMode = facingMode === 'user' ? 'environment' : 'user';

			const newStream = await navigator.mediaDevices.getUserMedia(constrains);

			videoTrack.stop();

			setStream(newStream);
		}
	};


    /**
     * 写真を撮影する
     */
    const onCapture = async () => {
        if(!canvasRef) throw new Error('canvas is not found');
        const context = canvasRef.current?.getContext('2d');
        if(!context) throw new Error('context is not found');

        const video = videoRef.current;
        if(!video) throw new Error('video is not found');

        const width = videoSize;
        const height = videoSize;

        context.drawImage(video, 0, 0, width, height);

        const base64Data = canvasRef.current!.toDataURL('image/jpeg', 0.92);
        setOpenRegistDialog(true);
        setRegistImageUrl(base64Data);

    }

    /**
     * 登録済みの画像を削除します
     * @param faceId 削除する顔写真ID
     */
    const onDeleteFaceImage = (faceId:string, pictureId: string) => {
        console.log(`faceId: ${faceId}  pictureId:${pictureId}`)
        if(window.confirm(`削除してもよろしいですか？`)){
            // const url = process.env.REACT_APP_WEBAPI_BASEURL + `/Rekognition/DeleteFace/${userId}/${faceId}`;
            const url = process.env.REACT_APP_WEBAPI_BASEURL + `/FaceRecognition/deleteFace/${userId}/${faceId}/${personId}/${pictureId}`;

            const headers = {
                "Authorization": "Bearer " + props.accessToken
            };

            console.log(`delete face request url:${url}`);

            axios.delete(url, { headers: headers }).then((res) => {
                console.log(`削除しました. userId=${userId}, faceId=${faceId}`);
                // 登録済み顔写真の再取得
                getRegisteredImages(userId);
                setNotify('success', '削除に成功しました');
            }).catch(error => {
                console.log(`削除に失敗しました。 error:${error}`);
                setNotify('error', error.message);
            })
        }
    }

    /**
     * userIDからpersonIDを取得します
     * @param userId ユーザID
     */
    const getPersonId = (userId: number) => {
        const url = process.env.REACT_APP_WEBAPI_BASEURL + `/Users/GetPersonId/${userId}`;

        const headers = {
            "Authorization": "Bearer " + props.accessToken
        };

        axios.get(url, { headers: headers }).then((res) => {
            console.log(`getPersonID:${res.data}`);
            if(res.status === 200){
                const personId = res.data;
                setPersonId(personId);
            }else{
                setNotify('error', '');
            }
        })
    }

    /**
     * アラートを閉じるためのハンドラ
     */
    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if(reason === 'clickaway'){
            return;
        }

        setOpen(false);
    }

    /**
     * ダイアログを閉じます
     */
    const onCloseInfoDialog = () => {
        setOpenInfoDialog(false);
        // ダイアログを閉じた際にLIFFアプリも閉じる
        if(isWindowClose){
            liff.closeWindow();
        }
    }

    /**
     * 登録確認ダイアログを閉じます
     */
    const onCloseRegistDialog = () => {
        setOpenRegistDialog(false);
    }

    /**
     * 登録結果ダイアログを閉じます
     */
    const onCloseResultDialog = () => {
        setRegistResult({isRegist: false, reasons: []});
        setOpenRegistDialog(false);
        setOpenResult(false);
    }

    /**
     * 顔分析と顔写真登録を行います
     * @param imageData 登録する写真のbase64文字列
     */
    const onSendFaceImage = async (imageData: string) => {
        console.log(`[START] onSendFaceImage`);
        setIsLoading(true);

        if(imageCount >= MAX_IMAGE_COUNT){
            // 顔写真の登録上限は3枚まで
            // 既に3枚登録されているのであればリクエストしない
            setOpenResult(true);
            const reason = ["顔写真の登録上限のため、これ以上登録できません。"]
            setRegistResult({isRegist: false, reasons: reason});
            setIsLoading(false);
            return;
        }

        const uploadURL = process.env.REACT_APP_WEBAPI_BASEURL + "/FaceRecognition/upload";
        const uploadRequest = {
            imageData: imageData,
            userID:userId,
            personID: personId !== '' ? personId : null
        }

        console.log(`url:${uploadURL}, request: userId: ${uploadRequest.userID} personID:${uploadRequest.personID}`);

        const headers = {
            "Authorization": "Bearer " + props.accessToken
        };

        await axios.post(uploadURL, uploadRequest, { headers: headers }).then(res => {
            const uploadResponseResult = res.data;

            console.log(`upload response:`);
            console.dir(uploadResponseResult)
            if(uploadResponseResult.status !== undefined && uploadResponseResult.status !== 200){
                // エラーがあるのでエラー表示
                let message;
                const code = uploadResponseResult.error.code;

                // Bio-IDiomから返却されたエラーコード
                switch (code) {
                    case '1001':
                        message = '不正な画像が指定されました'
                        break;
                    case '1002':
                        message = '複数の顔が検出されました。お一人で撮り直してください'
                        break;
                    case '1005':
                        message = '正面を向いてください。'
                        break;
                    case '1006':
                        message = 'マスクは完全に外した状態で撮影してください。'
                        break;
                    case '1007':
                        message = 'サングラスを外してください'
                        break;
                    case '1008':
                        message = '画像の品質が保てませんでした。手ブレなどに注意して撮影してください。'
                        break;
                    case '1009':
                        message = '顔画像の余白が少ないです。少し顔を離して撮影してください。'
                        break;
                    case '1010':
                        message = '目を開いた状態で撮影してください。'
                        break;
                    case '2001':
                        message = '顔が検出されませんでした。'
                        break;
                    default:
                        message = '登録に失敗しました。撮り直してください。'
                        break;
                }

                const errorMessage = {
                    isRegist: false,
                    reasons: [message]
                }

                setOpenResult(true);
                setRegistResult(errorMessage);
                setIsLoading(false);
            }else{
                // 登録成功
                setOpenResult(true);
                setRegistResult({isRegist:true, reasons:[]});
                setNotify('success', `アップロードが完了しました`);
                getRegisteredImages(userId);
                setIsLoading(false);
                if(personId === ''){
                    getPersonId(userId);
                }
            }
        }).catch(error => {
            console.error(error);
        });


        // const detectFaceUrl = process.env.REACT_APP_WEBAPI_BASEURL + "/Rekognition/DetectFaces";
        // const data = {
        //     imageData
        // }


        // // detect face
        // const detectFaceResponseresult = await axios.post(detectFaceUrl, data);
        // const detectFaceResult:DetectFacesResponse = detectFaceResponseresult.data;


        // // regist image
        // if(detectFaceResult.isRegist){
        //     const headers = {
        //         "Authorization": "Bearer " + props.accessToken
        //     }
        //     const registUrl = process.env.REACT_APP_WEBAPI_BASEURL + "/Rekognition/UploadFace";    // Amazon Rekognition向け
        //     const registData = {
        //         imageData: imageData,
        //         userID: userId
        //     }
        //     const registResponseResult = await axios.post(registUrl, registData, { headers: headers });
        //     setOpenResult(true);
        //     setRegistResult({isRegist:true, reasons:[]});
        //     setNotify('success', `アップロードが完了しました`);
        //     getRegisteredImages(userId);
        //     setIsLoading(false);

        // }else{
        //     setOpenResult(true);
        //     setRegistResult(detectFaceResult);
        //     setIsLoading(false);
        // }
    }

    /**
     * 通知用アラートへのセット関数
     * @param type アラートのタイプ(info, success, warning, error)
     * @param message 表示するメッセージ
     */
    const setNotify = (type: NotifyType, message: string) => {
        setOpen(true);
        setNotifyType(type);
        setNotifyMessage(message);
    }

    /**
     * 情報表示ダイアログのセット関数
     * @param title ダイアログタイトル
     * @param content ダイアログ表示内容
     * @param isWindowClose ダイアログを閉じる際にウィンドウを閉じるかどうか
     */
    const setInformationDialog = (title: string, content: string, isWindowClose: boolean) => {
        setOpenInfoDialog(true);
        setTitle(title);
        setContent(content);
        setIsWindowClose(isWindowClose);
    }

    return (
        <Container maxWidth='sm' sx={{pt:5}}>
            <Stack direction='column' alignItems='center' justifyContent='center' spacing={5} sx={{marginBottom:5}}>
                <AppBar color='secondary'>
                    <Typography mt={2} mb={2} align='center' variant='h4' component='h4'>
                        顔写真登録
                    </Typography>
                </AppBar>
                <video
                    id='face-camera-regist'
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    width={videoSize}
                    height={videoSize}
                    >
                </video>
                <IconButton sx={{position: 'absolute', top:50, left:20, zIndex:10, backgroundColor:'#7777', border:'1px solid #777'}} color="primary" aria-label="camera switch" component="label">
                    <CameraswitchIcon color='secondary' onClick={switchCamera} />
                </IconButton>
                <Button size='large' variant="contained" startIcon={<PhotoCamera />} onClick={onCapture} disabled={!cameraLoaded} >
                    撮影
                </Button>
                <div style={{marginTop:3}}>
                    顔写真登録数: {imageCount}
                </div>
                {imageListData?.length !== 0 &&
                    <ImageList images={imageListData} onDelete={onDeleteFaceImage}/>
                }
                <canvas id='face-picture' ref={canvasRef} style={{display:'none'}} width={videoSize} height={videoSize}/>
            </Stack>
            <RegistConfirmDialog open={openRegistDialog} imageUrl={registImageUrl} onClose={onCloseRegistDialog} onSubmit={onSendFaceImage} isLoading={isLoading}/>
            <ResultDialog open={openResult} isRegist={registResult.isRegist} reasons={registResult.reasons} onClose={onCloseResultDialog}/>
            <InformationDialog open={openInfoDialog} title={title} content={content} onClose={onCloseInfoDialog}/>
            <NotificationAlert open={open} type={notifyType} content={notifyMessage} onClose={handleCloseAlert}/>
        </Container>
    )
}

export default FaceRegister;
