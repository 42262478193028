import axios from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";

export class AccessTokenProvider {

    private _baseURL: string = "";
    private _token: string = "";
    private _ONE_HOURS_LATER = 1000 * 60 * 60 * 1;

    constructor(baseURL: string){
        this._baseURL = baseURL;
        this._token = "";

        this.checkTokenExpiresIn = this.checkTokenExpiresIn.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
    }

    /**
     * アクセストークンが有効期限内かどうかをチェックします.
     * 
     */
    async checkTokenExpiresIn(token: string | null):Promise<boolean>{
        console.log(`[start] checkTokenExpiresIn`);
        return new Promise<boolean>((resolve, reject) => {
            if(token == null || token === '') {
                console.log(`トークンの取得に失敗しました`);
                resolve(false);
                return;
            }

            // tokenをデコード
            const decode = jwtDecode(token) as JwtPayload;
            if(!decode || !decode.exp) return false;
            const now = new Date();

            // 桁が合わないため0埋めする
            let expStr = decode.exp.toString();
            let expDigit = expStr.length;
            let nowStr = now.getTime().toString();
            let nowDigit = nowStr.length;

            if(expDigit < nowDigit){
                const count = nowDigit - expDigit;
                if(count !== 0){
                    for(let i=0; i<count; i++){
                        expStr = expStr + "0";
                    }
                }
            }else if(expDigit > nowDigit){
                const count = expDigit - nowDigit;
                if(count !== 0){
                    for(let i=0; i<count; i++){
                        nowStr = nowStr + "0";
                    }
                }
            }

            const expiredDate = new Date(Number(expStr));
            const nowDate = new Date(Number(nowStr));

            // 期限切れチェック
            if(expiredDate.getTime() >= (nowDate.getTime() + this._ONE_HOURS_LATER)){
                console.log(`有効期限切れです`);
                resolve(false);
            }else{
                console.log(`利用可能なトークンです`)
                resolve(true);
            }
        })
        
    }

    /**
     * WebAPIへのアクセストークンを取得します.
     */
    async getAccessToken():Promise<string>{
        const valid = await this.checkTokenExpiresIn(this._token);
        console.log(`getAccessToken valid:${valid}`)
        if(valid) return '';

        const apiUrl = this._baseURL + '/login'

        const loginID = process.env.REACT_APP_LOGIN_ID;
        const loginPass = process.env.REACT_APP_LOGIN_PASSWORD;

        const data = {
            id: loginID,
            password: loginPass
        }

        return new Promise((resolve, reject) => {
            axios.post(apiUrl, data).then(response => {
                this._token = response.data.token;
                console.log(`トークン取得に成功しました`);
                resolve(this._token);
            }).catch(err => {
                console.error("トークン取得時にエラーが発生しました.", err);
                this._token = '';
                resolve('')
            })
        })

    }

    get():string {
        return this._token;
    }


}