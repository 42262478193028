import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

type ResultDialogProps = {
    open: boolean;
    isRegist: boolean;
    reasons: string[];
    onClose: () => void
}

const ResultDialog:React.FC<ResultDialogProps> = ({open, isRegist, reasons, onClose}) => {

    const resultContent = () => {
        if(isRegist === true){
            return (
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <DialogTitle style={{ backgroundColor: '#4caf50', color: '#fff' }}>
                        <CheckBoxIcon color='inherit' style={{ marginRight: '8px' }} /> Success
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: '#f5f5f5' }}>
                        <DialogContentText style={{ color: '#333' }}>
                            顔写真の登録に成功しました！
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#f5f5f5' }}>
                        <Button onClick={onClose} color="primary" variant="contained" style={{ backgroundColor: '#4caf50', color: '#fff' }}>
                            OK
                        </Button>
                    </DialogActions>
                </div>
            )
        }else{
            return (
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    <DialogTitle textAlign={'center'} style={{ backgroundColor: '#f44336', color: '#fff' }}>
                        <ReportProblemIcon sx={{ color: 'inherit', marginRight: '8px' }} /> 登録失敗
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: '#f5f5f5' }}>
                        <DialogContentText style={{ color: '#333', marginBottom: '16px', textAlign: 'center', fontSize: '18px' }}>
                            登録に失敗しました。
                        </DialogContentText>
                        {reasons.length > 0 && (
                            <div style={{ marginBottom: '16px' }}>
                                <DialogContentText style={{ color: '#f44336', textAlign: 'center' }}>
                                    <strong>失敗理由:</strong>
                                </DialogContentText>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {reasons.map((reason: string, index: number) => (
                                        <li key={index}>
                                            <DialogContentText style={{ color: '#f44336', textAlign: 'center' }}>{reason}</DialogContentText>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#f5f5f5', justifyContent: 'center' }}>
                        <Button onClick={onClose} color="primary" variant="contained" style={{ backgroundColor: '#f44336', color: '#fff' }}>
                            OK
                        </Button>
                    </DialogActions>
                </div>
            )
        }
    }


    return (
        <Dialog 
            open={open}
            onClose={onClose}
            onBackdropClick={onClose}
            fullWidth={true}
            >
            {resultContent()}
        </Dialog>
    )
}

export default ResultDialog;