import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

export type InformationDialogProps = {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
}

const InformationDialog:React.FC<InformationDialogProps> = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}>

            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default InformationDialog;