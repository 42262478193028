import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

type RegistConfirmDialogProps = {
    open: boolean,
    imageUrl: string,
    onClose: () => void,
    onSubmit: (data:string) => void,
    isLoading: boolean
}

const RegistConfirmDialog:React.FC<RegistConfirmDialogProps> = ({open, imageUrl, onClose, onSubmit, isLoading}) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}>

            <DialogTitle>
                顔写真登録確認
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    こちらの画像を登録しますか？
                </DialogContentText>
                <DialogContentText>
                    <img alt='regist-face' src={imageUrl}></img>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={isLoading} startIcon={<SaveIcon/>} loadingPosition="start" variant="contained" autoFocus onClick={() => onSubmit(imageUrl)}>
                    <span>OK</span>
                </LoadingButton>
                <Button onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default RegistConfirmDialog;